import React, { useState, useEffect } from 'react';
import './App.css';
import logoImage from './assets/logo.png';

function App() {
  // 목표 시간을 2024년 11월 9일 새벽 5시 5분으로 설정
  const targetDate = new Date('2024-11-18T00:00:00+09:00').getTime();
  const redirectUrl = 'https://www.naver.com';

  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    let timer = null;

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference < 1000) {
        if (timer) {
          clearInterval(timer);
        }
        window.location.href = redirectUrl;
        return null;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    };

    const initialTimeLeft = calculateTimeLeft();
    if (initialTimeLeft) {
      setTimeLeft(initialTimeLeft);
    }

    timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft === null) {
        clearInterval(timer);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [targetDate, redirectUrl]);

  if (!timeLeft) return null;

  return (
    <div className="App">
      <div className="content">
        <img 
          src={logoImage} 
          alt="END TO AND" 
          className="logo-image"
        />
        <p className="subtitle">새로운 시작을 향한 우리들의 창의적 여정</p>
        <div className="countdown">
          <span className="day">DAY-{timeLeft.days}</span>
          <div className="time-container">
            <div className="time-top">
              <span className="hour">{String(timeLeft.hours).padStart(2, '0')}</span>
              <span className="ampm">{timeLeft.hours >= 12 ? 'PM' : 'AM'}</span>
            </div>
            <div className="minutes-seconds">
              <span className="time-small">
                {String(timeLeft.minutes).padStart(2, '0')}:
                {String(timeLeft.seconds).padStart(2, '0')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
